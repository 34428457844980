
header {
	nav li a {
		color: $white;
	}
}
 

.nav-link { 

	padding: 0px 20px !important;



}

#mainContent {

	.title-header {
		border-top-style: dashed ; 
		letter-spacing: 1rem;
		font-weight: 700 ;
		padding: 20px;
	}

}


#footer {
		color: $white;

		strong {
			font-size: 1.5rem;
			padding-bottom:20px;
			display:block;
		}

}


footer#copyright {
	
		color: $white;
}


.first-char-big p:first-child::first-letter {
	font-size: 24px;
	font-weight: 700;
}