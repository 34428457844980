

.col_last { margin-right: 0!important; clear: right;}

.uppercase { text-transform: uppercase !important; }

.lowercase { text-transform: lowercase !important; }

.capitalize { text-transform: capitalize !important; }

.nott { text-transform: none !important; }

.tright { text-align: right !important; }

.tleft { text-align: left !important; }

.fright { float: right !important; }

.fleft { float: left !important; }

.fnone { float: none !important; }

.line,
.double-line {
	clear: both;
	position: relative;
	width: 100%;
	margin: 60px 0;
	border-top: 1px solid #EEE;
}

.line.line-sm { margin: 30px 0; }

.double-line { border-top: 3px double #E5E5E5; }

.allmargin { margin: 50px !important; }

.leftmargin { margin-left: 50px !important; }

.rightmargin { margin-right: 50px !important; }

.topmargin { margin-top: 50px !important; }

.bottommargin { margin-bottom: 50px !important; }

.clear-bottommargin { margin-bottom: -50px !important; }

.allmargin-sm { margin: 30px !important; }

.leftmargin-sm { margin-left: 30px !important; }

.rightmargin-sm { margin-right: 30px !important; }

.topmargin-sm { margin-top: 30px !important; }

.bottommargin-sm { margin-bottom: 30px !important; }

.clear-bottommargin-sm { margin-bottom: -30px !important; }

.allmargin-lg { margin: 80px !important; }

.leftmargin-lg { margin-left: 80px !important; }

.rightmargin-lg { margin-right: 80px !important; }

.topmargin-lg { margin-top: 80px !important; }

.bottommargin-lg { margin-bottom: 80px !important; }

.clear-bottommargin-lg { margin-bottom: -80px !important; }

.nomargin {margin: 0 !important; }

.noleftmargin { margin-left: 0 !important; }

.norightmargin { margin-right: 0 !important; }

.notopmargin { margin-top: 0 !important; }

.nobottommargin { margin-bottom: 0 !important; }

.header-stick { margin-top: -50px !important; }

.content-wrap .header-stick { margin-top: -80px !important; }

.footer-stick { margin-bottom: -50px !important; }

.content-wrap .footer-stick { margin-bottom: -80px !important; }

.noborder { border: none !important; }

.noleftborder { border-left: none !important; }

.norightborder { border-right: none !important; }

.notopborder { border-top: none !important; }

.nobottomborder { border-bottom: none !important; }

.noradius { border-radius: 0 !important; }

.col-padding { padding: 60px; }

.nopadding { padding: 0 !important; }

.noleftpadding { padding-left: 0 !important; }

.norightpadding { padding-right: 0 !important; }

.notoppadding { padding-top: 0 !important; }

.nobottompadding { padding-bottom: 0 !important; }

.noabsolute { position: relative !important; }

.noshadow { box-shadow: none !important; }

.hidden { display: none !important; }

.nothidden { display: block !important; }

.textcenter { text-align: center !important; }

.divcenter {
	position: relative !important;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.t300 { font-weight: 300 !important; }

.t400 { font-weight: 400 !important; }

.t500 { font-weight: 500 !important; }

.t600 { font-weight: 600 !important; }

.t700 { font-weight: 700 !important; }

.noheight { height: 0 !important; }

.nolineheight { line-height: 0 !important; }

.imagescale, .imagescalein {
	display: block;
	overflow: hidden;

	img {
		transform: scale(1);
		transition: transform 1s ease;
	}

	&:hover img {
		transform: scale(0.9);
	}
}

.imagescalein {
	img {
		transform: scale(0.9);
	}

	&:hover img {
		transform: scale(1);
	}
}

.grayscale {
	filter: brightness(80%) grayscale(1) contrast(90%);
	transition: 1s filter ease;
}

.grayscale:hover {
	filter: brightness(100%) grayscale(0);
}