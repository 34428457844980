// xs
@media (max-width: 575px) {
	
}

// sm
@media (min-width: 576px) and (max-width: 767px) {
	
}

// md
@media (min-width: 768px) and (max-width: 991px) {
	
}

// l
@media (min-width: 992px) and (max-width: 1199px) {
	
}

// xl
@media (min-width: 1200px) {

}



// <=sm
@media (max-width: 767px) {

}

// <=md
@media (max-width: 991px) {
	
}

// <=l
@media (max-width: 1199px) {

}