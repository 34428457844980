$transition-short-duration: 0.1s;
$transition-hover-duration: 0.3s;

$h1size: 54px;
$h1height: ($h1size * 1.1);
$h2size: 46px;
$h2height: ($h2size * 1.1);
$h3size: 22px;
$h3height: ($h3size * 1.1);
$psize: 18px;
$pheight: ($psize * 1.5);

$menu-height: 137px;
$menu-bottom-padding: 90px;
$menu-items-padding: 18px;
$menu-items-font-size: 20px;

$footer-height: 280px;
$footer-top-padding: 90px;